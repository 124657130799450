import { useEffect, useState } from "react";
import Countdown, {zeroPad} from "react-countdown";
import { LinearProgress, LinearProgressProps, Box, Typography } from "@material-ui/core";
import { CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  maxTokens: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Home = (props: HomeProps) => {
  const [itemsSold, setItemsSold] = useState<number>();
  const [percentSold, setPercentSold] = useState<number>();
  const [isSaleActive, setIsSaleActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const wallet = useAnchorWallet();

  const onMint = async () => {
    if (isSoldOut || isMinting || !isSaleActive) { 
        return;
    } 
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
        console.log(error);
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;

        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection
        );

      setStartDate(goLiveDate);
      setItemsSold(props.maxTokens-itemsRemaining);
      setPercentSold( 100.0*(props.maxTokens-itemsRemaining)/props.maxTokens);
      setIsSoldOut(itemsRemaining === 0);
      if (!wallet) { setPercentSold(100.0); return; }
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.maxTokens, props.candyMachineId, props.connection]);

  // update sold amount every 5 seconds
  useEffect(() => {
    (async () => {
      if (!wallet) { setPercentSold(100.0); } return;
      setInterval(async() => {
            const { candyMachine, goLiveDate, itemsRemaining } =
                await getCandyMachineState(
                    wallet as anchor.Wallet,
                    props.candyMachineId,
                    props.connection
                );
            setItemsSold(props.maxTokens-itemsRemaining);
            setPercentSold( 100.0*(props.maxTokens-itemsRemaining)/props.maxTokens);
            setIsSoldOut(itemsRemaining === 0);
      }, 5000);
    })();
  }, [wallet, props.maxTokens, props.candyMachineId, props.connection]);

  return (
    <main>
        {!isSaleActive &&
            <div id="minter_screen">
                <span id="cost">10000</span> 
                <span id="small"></span>
                <span id="gecko">SOLD</span>
                <Countdown
                    date={startDate}
                    onMount={({ completed }) => completed && setIsSaleActive(true)}
                    onComplete={() => setIsSaleActive(true)}
                    renderer={renderCounter}
                />
            </div>
        }
        {isSaleActive &&
            <div id="minter_screen">
                <span id="cost" className="open">
                    {wallet ? (
                        ("00000" + itemsSold).slice(-5)
                    ) : ( 
                        "10000"
                    )}
                </span> 
				<span id="gecko" className="open">SOLD</span>
                <span id="progress_bar">
					{wallet && 
                        <LinearProgressWithLabel variant="determinate" value={percentSold as number}/>
                    }
                </span>

				<span id="connected_as">
					{wallet ? (
						"CONNECTED AS: "+shortenAddress(wallet.publicKey.toBase58())+"."
					) : (
						"gecko@GARAGE-37GF3L1:~$ "
					)}
          <span id="cursor">_</span>
				</span>
			</div>
        }

        {!wallet ? (
		  <div id="mint_button">
            <span id="connect">
			  <WalletDialogButton>SOLD OUT</WalletDialogButton>
            </span>
		  </div>
        ) : (
          <div id="mint_button" onClick={onMint}>
            <span id="text">
                "SOLD OUT"
            </span>
          </div>
        )}

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <span id="timer">
        {zeroPad(days)}d:{zeroPad(hours)}h:{zeroPad(minutes)}m:{zeroPad(seconds)}s TO DROP
    </span>
  );
};

export default Home;
